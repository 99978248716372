<template>
  <span class="a-added-product" />
</template>

<script>
export default {
  name: 'AProductAdded'
}
</script>

<style lang="scss" scoped>
@import '~theme/css/fonts';

.a-added-product {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--spacer-58);
  height: var(--spacer-40);
  background-color: var(--orange);

  @include font-icon(var(--icon-checkmark));

  &:before {
    color: var(--white);
    font-size: var(--font-size-12);
  }
}
</style>
